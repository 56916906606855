//this is single product page

import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import { Link, useParams } from 'react-router-dom';
import Axios from '../../axios/axios'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from './style.css'
import Availability from './AvailabilitySmall'



const PackageSingle = () => {



    const [isloading, setIsloading] = useState(false);
    const [pack, setPack] = useState([])
    const [packageproduct, setPackageProduct] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const { id } = useParams()
    const [cart, setCart] = useState([]);
    const [availability, setAvailability] = useState('');

    let isCartAdded = cart?.some((data) => data.product == id);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [today, setToday] = useState('')
    const [days, setDays] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [cartAdd, setAddCart] = useState('');
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {

        const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        setUserInfo(storedUserInfo);
    }, []);


    useEffect(() => {
        if (availability === 200) {
            setShowSuccessAlert(true);
            const successTimer = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000); // 5 seconds

            // Clear the timer when the component unmounts or `availability` changes
            return () => clearTimeout(successTimer);
        } else if (availability === 400) {
            setShowErrorAlert(true);
            const errorTimer = setTimeout(() => {
                setShowErrorAlert(false);
            }, 5000); // 5 seconds

            return () => clearTimeout(errorTimer);
        }
    }, [availability]);

    useEffect(() => {
        // Function to get today's date in "YYYY-MM-DD" format

        setToday(getTodayDate())

        // Set the initial value of startDate to today's date
        setStartDate(getTodayDate());
        setEndDate(getTodayDate());
    }, []);
    useEffect(() => {
        fetchData()
    }, [id])

    useEffect(() => {
        if (packageproduct.length > 0) {
            fetchProductNames()
        }
    }, [packageproduct])

    useEffect(() => {
        fetchCart();
    }, []);

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchData = async () => {
        try {
            const res = await Axios.get(`/api/package/${id}`)
            console.log(res.data)
            setPack(res.data)
            setPackageProduct(res.data.product || []);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log(packageproduct, "product")

    const fetchProductNames = async () => {
        try {
            const productPromises = packageproduct.map((productId) =>
                Axios.get(`/api/products/${productId}`).then((response) => response.data.name)
            );
            const names = await Promise.all(productPromises);
            setProductNames(names);

        } catch (error) {
            console.error("Error fetching product names:", error);
        }
    };

    console.log(productNames, "product list")

    const fetchCart = async () => {
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            const response = await Axios.get(`api/cart/${storedUserInfo._id}`);
            setCart(response?.data?.product);
            console.log(response?.data?.product);
        } catch (error) {
            console.log('Error fetching cart list:', error.message);
        }
    };


    // search availability of product/////////////////
    const availabilityCheck = async () => {
        try {


            if (days < 1 || quantity < 1) {
                toast.error('Days and quantity must be greater than or equal to one.');
                setIsloading(false);
                return;
            }


            const data = {
                startDate,
                endDate,
                packageId: id,
                qnty: quantity,
            };

            console.log(data)

            const res = await Axios.post(`api/package/check-packageavailability`, data);
            if (res.data.code === 200) {
                if (res.data.balStock < quantity) {
                    toast.error(`Only ${res.data.balStock} stock are available`);
                } else {
                    toast.success(`Available `);
                }
            } else {
                setAvailability(res.data.code);
                toast.error('Items Not available for this date');
            }
        } catch (error) {
            console.error('Error during availability check:', error);
            toast.error('Error checking availability');
        }
    };

    const handleQuantity = (value) => {
        setQuantity(value);
    };

    const handleNumberOfDays = (val, date_) => {
        setDays(val);

        const startDate_ = new Date(date_);
        startDate_.setDate(startDate_.getDate() + parseInt(val, 10) - 1);

        const year = startDate_.getFullYear();
        const month = startDate_.getMonth() + 1; // Months are 0-indexed, so add 1
        const day = startDate_.getDate();

        let date = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''
            }${day}`;

        setEndDate(date);
    };


    // add to cart////////////////////
    const addToCart = async () => {
        setIsloading(true);

        try {
            // Validate days and quantity
            if (days < 1 || quantity < 1) {
                toast.error('Days and quantity must be greater than or equal to one.');
                setIsloading(false);
                return;
            }

            // Perform availability check for the entire package
            const availabilityCheckData = {
                startDate,
                endDate,
                packageId: id, // Use the package ID
                qnty: quantity,
            };

            const availabilityRes = await Axios.post(`api/package/check-packageavailability`, availabilityCheckData);

            if (availabilityRes.data.code !== 200) {
                toast.error('Items Not available for this date');
                setIsloading(false);
                return;
            }

            if (availabilityRes.data.balStock < quantity) {
                toast.error(`Only ${availabilityRes.data.balStock} stock are available`);
                setIsloading(false);
                return;
            } else {
                toast.success(`Items available`);
            }

            // Check if the user is logged in
            if (!userInfo) {
                alert('Please log in to add items to your cart.');
                window.location.href = '/login';
                setIsloading(false);
                return;
            }

            // Iterate over each product ID in the package data
            for (const productId of pack.product) {
                // Fetch product details using the product ID
                const productRes = await Axios.get(`api/products/${productId}`);
                const product = productRes.data;

                // Calculate the total amount for this product
                const CalcAmount = product.offerPrice * days * quantity;

                const data = {
                    user: userInfo?._id,
                    totalAmount: CalcAmount,
                    product: product._id,
                    packageId: id, // Include package ID for each item
                    startDate,
                    endDate,
                    days,
                    qnty: quantity,
                    amount: product.offerPrice,
                };

                // Add each product to the cart
                const res = await Axios.post(`api/cart`, data);
                console.log(res.data, "cart data")
                if (res.data.code === 200) {
                    toast.success(`Added ${product.name} to cart`);
                } else {
                    toast.error(`Failed to add ${product.name} to cart`);
                }
            }

            // Final success message after all products have been added
            toast.success('Package successfully added to cart');
            setAddCart(200);
            window.location.reload();
        } catch (error) {
            console.log('Error during add to cart: ', error.message);
            toast.error('Error adding package to cart');
        } finally {
            setIsloading(false);
        }
    };

    //book now

    const booknow = async () => {
        setIsloading(true);

        try {
            // Validate days and quantity
            if (days < 1 || quantity < 1) {
                toast.error('Days and quantity must be greater than or equal to one.');
                return;
            }

            // Perform availability check
            const availabilityCheckData = {
                startDate,
                endDate,
                packageId: id, // Use the package ID
                qnty: quantity,
            };

            const availabilityRes = await Axios.post(`api/package/check-packageavailability`, availabilityCheckData);

            if (availabilityRes.data.code !== 200) {
                toast.error('Items Not available for this date');
                return;
            }

            if (availabilityRes.data.balStock < quantity) {
                toast.error(`Only ${availabilityRes.data.balStock} stock are available`);
                return;
            }

            toast.success('Available');

            // Check if the user is logged in
            if (!userInfo) {
                toast.error('Please log in to add items to your cart.');
                window.location.href = '/login';
                return;
            }

            // Loop through each product in the package and add it to the cart
            for (const productId of pack.product) {
                // Fetch product details using the product ID
                const productRes = await Axios.get(`api/products/${productId}`);
                const product = productRes.data;

                // Calculate the total amount for this product
                const CalcAmount = product.offerPrice * days * quantity;

                const data = {
                    user: userInfo?._id,
                    totalAmount: CalcAmount,
                    product: product._id,
                    packageId: id, // Include package ID for each item
                    startDate,
                    endDate,
                    days,
                    qnty: quantity,
                    amount: product.offerPrice,
                };

                // Add each product to the cart
                const res = await Axios.post(`api/cart`, data);
                if (res.data.code === 200) {
                    toast.success(`Added ${product.name} to cart`);
                } else {
                    toast.error(`Failed to add ${product.name} to cart`);
                }
            }

            // Redirect to cart if all items are added successfully
            window.location.href = '/cart';

        } catch (error) {
            console.error('Error during add to cart:', error.message);
            toast.error('Error adding to cart');
        } finally {
            setIsloading(false);
        }
    };



    return (
        <body className="template-index index-demo4">
            <div className="page-wrapper">
                <div className="classic-topHeader">
                    <Header />
                    <div id="page-content">
                        {' '}
                        <div class="page-header text-center">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="breadcrumbs">
                                            <a href="#" title="Back to the home page">
                                                Home
                                            </a>
                                            <span class="main-title fw-bold">
                                                <i class="icon anm anm-angle-right-l"></i>Package
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                        <div class="container">
                            <div class="product-single">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-img mb-4 mb-md-0">
                                        <div class="product-details-img product-horizontal-style">
                                            <div class="zoompro-wrap">
                                                <div class="zoompro-span">
                                                    <img
                                                        id="zoompro"
                                                        class="zoompro"
                                                        // src={singleproduct?.image1}
                                                        src={
                                                            pack?.photo
                                                                ? pack?.photo
                                                                : '/assets/images/products/noimage.png'
                                                        }
                                                        // data-zoom-image={singleproduct?.image1}
                                                        // alt="assets/images/products/noimage.jpg"
                                                        width="325"
                                                        height="508"
                                                    />
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}><Availability /></div>



                                                <div class="product-labels">

                                                </div>

                                                <div class="product-buttons">
                                                    <a
                                                        href="#;"
                                                        class="btn btn-primary prlightbox"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        title="Zoom Image"
                                                    >
                                                        <i class="icon anm anm-expand-l-arrows"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-info">
                                        <div class="product-single-meta">
                                            <h2 class="product-main-title">
                                                {/* {singleproduct?.name} */}
                                            </h2>

                                            <div class="product-info">
                                                <p class="product-stock d-flex">
                                                    Package Name:
                                                    <span class="pro-stockLbl ps-0">
                                                        <span class="d-flex-center stockLbl instock text-uppercase">
                                                            {pack?.name}
                                                        </span>
                                                    </span>
                                                </p>
                                                <p class="product-vendor">
                                                    Price:
                                                    <span class="text">
                                                        ₹{pack?.specialPrice}
                                                    </span>
                                                </p>
                                                <p class="product-vendor">
                                                    Price:
                                                    <span class="text">
                                                        <strike>₹{pack?.price}</strike>
                                                    </span>
                                                </p>
                                            </div>

                                            <div class="product-price d-flex-center my-3">
                                                <span class="price old-price">
                                                    {/* {singleproduct?.price} */}
                                                </span>{' '}
                                                {/* &nbsp;Per Day */}
                                                {/* <span class="price">₹{singleproduct?.offerPrice}</span> */}
                                            </div>
                                            <div className="products-included">
                                                <h5>Products Included</h5>
                                                <ul>
                                                    {productNames.length > 0 ? (
                                                        productNames.map((name, index) => <li key={index}>{name}</li>)
                                                    ) : (
                                                        <li>Loading product names...</li>
                                                    )}
                                                </ul>
                                            </div>

                                            <hr />
                                            <h3 className="text-uppercase mb-0" style={{ fontFamily: "sans-serif" }}>
                                                {/* {singleproduct?.description} */}
                                            </h3>
                                        </div>

                                        <div class="product-form product-form-border hidedropdown">
                                            <div class="product-action w-100 d-flex-wrap my-3 my-md-4">
                                                <div class="col-md-4 mb-4 stretch-card transparent">
                                                    <p class="mb-4">Start Date</p>
                                                    <input
                                                        min={today}
                                                        type="date"
                                                        className="form-control"
                                                        required
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    {' '}
                                                    <p class="mb-4">Days</p>
                                                    <input
                                                        min="1"
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        value={days}
                                                        onChange={(e) =>
                                                            handleNumberOfDays(e.target.value, startDate)
                                                        }
                                                        disabled={startDate === '' ? true : false}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    {' '}
                                                    <p class="mb-4">Quantity</p>
                                                    <input
                                                        min="1"
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        value={quantity}
                                                        onChange={(e) => handleQuantity(e.target.value)}
                                                    />
                                                </div>




                                                {availability === 200 && (
                                                    <div
                                                        class="alert alert-success alert-dismissible fade show mt-2"
                                                        role="alert"
                                                    >
                                                        <strong>Items available for this date!</strong>
                                                        <button
                                                            type="button"
                                                            class="close"
                                                            data-dismiss="alert"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                )}

                                                {showSuccessAlert && (
                                                    <div
                                                        className="alert alert-success alert-dismissible fade show mt-2"
                                                        role="alert"
                                                    >
                                                        <strong>Items available for this date!</strong>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="alert"
                                                            aria-label="Close"
                                                            onClick={() => setShowSuccessAlert(false)} // Manually close the alert
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                )}

                                                {showErrorAlert && (
                                                    <div
                                                        className="alert alert-danger alert-dismissible fade show mt-2"
                                                        role="alert"
                                                    >
                                                        <strong>Not Available!</strong> Items not available for this date.
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="alert"
                                                            aria-label="Close"
                                                            onClick={() => setShowErrorAlert(false)} // Manually close the alert
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                <div class="product-form-submit addcart fl-1 ms-3">
                                                    <button
                                                        type="submit"
                                                        name="add"
                                                        // class="btn btn-secondary product-form-cart-submit"
                                                        className={`btn btn-lg checkout w-100  
                                                     `}
                                                        onClick={(e) => availabilityCheck()}

                                                    >

                                                        CHECK AVAILABILITY
                                                    </button>
                                                </div>
                                                <div class="product-form-submit addcart fl-1 ms-3">
                                                    {isCartAdded === false ? (
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                {isloading ? (
                                                                    <button
                                                                        type="button"
                                                                        name="add"
                                                                        className="btn btn-lg my-4 checkout w-45 loading"
                                                                        disabled
                                                                    >
                                                                        Loading...
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        name="add"
                                                                        className="btn btn-lg my-4 checkout w-45"
                                                                        onClick={() => addToCart()}
                                                                    >
                                                                        Add to cart
                                                                    </button>
                                                                )}
                                                            </div>

                                                            <div className='col-6'>
                                                                {isloading ? (
                                                                    <button
                                                                        type="button"
                                                                        name="add"
                                                                        className="btn btn-lg my-4 checkout w-45 loading"
                                                                        disabled
                                                                    >
                                                                        Processing...
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="submit"
                                                                        name="add"
                                                                        className="btn btn-lg my-4 checkout w-45"
                                                                        onClick={() => booknow()}
                                                                        disabled={availability ? true : false}
                                                                    >
                                                                        Book Now
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Link to="/cart">
                                                            <button
                                                                type="submit"
                                                                name="add"
                                                                className="btn btn-lg my-4 checkout w-100"
                                                            >
                                                                View Cart
                                                            </button>
                                                        </Link>
                                                    )}
                                                    <button
                                                        type="submit"
                                                        name="add"
                                                        className="btn btn-secondary product-form-sold-out d-none"
                                                        disabled="disabled"
                                                    >
                                                        <span>Out of stock</span>
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        name="add"
                                                        className="btn btn-secondary product-form-pre-order d-none"
                                                    >
                                                        <span>Pre-order Now</span>
                                                    </button>
                                                </div>


                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>{' '}
            </div>
        </body>
    );
};

export default PackageSingle;
