import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Axios from '../../axios/axios';
import './style.css'
import MainHeader from '../../components/Layout/Header/MainHeader';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/Header';

const OrderDateSelector = () => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [orderedDates, setOrderedDates] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false); // Controls calendar visibility
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");

  useEffect(() => {
    fetchData(); // Call fetch data on component mount
  }, []);

  const fetchData = async () => {
    try {
      const res = await Axios.get(`/api/products`);
      const { products } = res.data;
      setProductList(products); // Set the product list
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleFetchOrderedDates = async () => {
    setLoading(true);


    try {
      const response = await Axios.get('/api/products/days/order', {
        params: {
          productId: selectedProductId,
          month: selectedMonth
        },
      });
      setOrderedDates(response.data.orderedDates.map(date => new Date(date)));
      setShowCalendar(true);
    } catch (error) {
      console.error('Error fetching ordered dates:', error);
    } finally {
      setLoading(false);
    }
  };
  console.log(orderedDates, "datesarray")

  const handleMonthChange = (e) => {
    const month = e.target.value;
    setSelectedMonth(month);
    handleFetchOrderedDates(month);
  };

  // Highlighting logic for the calendar
  const tileClassName = ({ date, view }) => {

    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];

      let originalOrderedDates = orderedDates.map(date => date.toISOString().split('T')[0]);

      console.log(originalOrderedDates)
      if (originalOrderedDates.includes(dateString)) {
        return "highlight-red"; // Highlight ordered dates in red
      } else {
        return "highlight-green"; // Highlight available dates in green
      }
    }
    return "";
  };

  const getCalendarDate = () => {
    const currentYear = new Date().getFullYear();
    return selectedMonth ? new Date(currentYear, selectedMonth - 1) : new Date(); // Adjust for zero-based month index
  };





  return (
    <>
      <Header />

      <div className="container text-center">
        <h2>Select Product and Date Range</h2>

        <div className="row justify-content-center mb-3">
          {/* Product Selector */}
          <div className="col-md-5">
            <div className="mb-3">
              <label className="form-label">Select Product:</label>
              <select
                value={selectedProductId}
                onChange={(e) => setSelectedProductId(e.target.value)}
                className="form-select"
              >
                <option value="" disabled>
                  Select a product
                </option>
                {productList.map((pro) => (
                  <option key={pro._id} value={pro._id}>
                    {pro.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Month Selector */}
          <div className="col-md-5">
            <div className="mb-3">
              <label className="form-label">Select Month:</label>
              <select value={selectedMonth} onChange={handleMonthChange} className="form-select">
                <option value="" disabled>Select a month</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i + 1}>
                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <button onClick={handleFetchOrderedDates} className="btn btn-primary mb-3">
          {loading ? 'Loading...' : 'Fetch Ordered Date'}
        </button>

        {/* Calendar Component */}
        {showCalendar && (
          <div className='d-flex justify-content-center mb-4'>
            <Calendar
              tileClassName={tileClassName}
              value={getCalendarDate()}
            />
          </div>
        )}
      </div>
      <Footer />
    </>

  );
};

export default OrderDateSelector;
