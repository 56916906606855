import React, { useState, useEffect } from 'react';
import Axios from '../../axios/axios';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css'
import MainHeader from '../../components/Layout/Header/MainHeader';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/Header';

const Availability = () => {
    const [selectedMonth, setSelectedMonth] = useState('');
    const [orderedDates, setOrderedDates] = useState([]);

    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [packageList, setPackageList] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState("");

    useEffect(() => {
        fetchData(); // Call fetch data on component mount
    }, []);

    const fetchData = async () => {
        try {
            const res = await Axios.get(`/api/package`);
            setPackageList(res.data); // Set the product list
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const handleFetchOrderedDates = async () => {
        if (!selectedProductId || !selectedMonth) return; // Avoid fetching if required params are missing

        setLoading(true);
        try {
            const response = await Axios.get('/api/package/days/order', {
                params: {
                    packageId: selectedProductId,
                    month: selectedMonth
                },
            });
            // Convert dates to Date objects
            setOrderedDates(response.data.orderedDates.map((date: string) => new Date(date)));
            setShowCalendar(true);
        } catch (error) {
            console.error('Error fetching ordered dates:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMonthChange = (e) => {
        const month = e.target.value;
        setSelectedMonth(month);
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateString = date.toLocaleDateString('en-CA'); // Format date to 'YYYY-MM-DD'

            const orderedDateStrings = orderedDates.map(date => date.toLocaleDateString('en-CA')); // Convert ordered dates to string format
            if (orderedDateStrings.includes(dateString)) {
                return "highlight-red"; // Highlight ordered dates in red
            } else {
                return "highlight-green"; // Highlight available dates in green
            }
        }
        return "";
    };

    const getCalendarDate = () => {
        const currentYear = new Date().getFullYear();
        const date = selectedMonth
            ? new Date(currentYear, parseInt(selectedMonth) - 1, 1) // Adjust month as it’s 0-indexed in JavaScript
            : new Date();

        // Force time to start at the beginning of the day in local time
        date.setHours(0, 0, 0, 0);
        return date;
    };

    return (
        <>
            <Header />

            <div className="container text-center">
                <h2>Select Package and Date Range</h2>

                {/* Product Selector, Month Selector, and Fetch Button in the Same Row */}
                <div className="row justify-content-center mb-3">
                    {/* Product Selector */}
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">Select Package:</label>
                            <select
                                value={selectedProductId}
                                onChange={(e) => setSelectedProductId(e.target.value)}
                                className="form-select"
                            >
                                <option value="" disabled>Select a product</option>
                                {packageList.map((pro) => (
                                    <option key={pro._id} value={pro._id}>{pro.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Month Selector */}
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label className="form-label">Select Month:</label>
                            <select value={selectedMonth} onChange={handleMonthChange} className="form-select">
                                <option value="" disabled>Select a month</option>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={i + 1}>
                                        {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Fetch Button */}
                    <div className="col-md-4">
                        <div className="mt-3">
                            <button onClick={handleFetchOrderedDates} className="btn btn-success w-100">
                                {loading ? 'Loading...' : 'Fetch'}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Calendar Component */}
                {showCalendar && (
                    <div className="row justify-content-center mb-4">
                            <Calendar
                                tileClassName={tileClassName}
                                value={getCalendarDate()}
                            />
                    </div>
                )}
            </div>
x

            <Footer />
        </>
    );
};

export default Availability;
